(function () {
  'use strict';

  class PollsRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    polls() {
      return this.$resource(`${this.env.api}/polls/:id`, {id: '@poll.id'}, {update: {method: 'PUT'}});
    }

    response() {
      return this.$resource(`${this.env.api}/polls/:id/poll_question_responses`, {id: '@poll_question_response.id'}, {update: {method: 'PUT'}});
    }
  }

  /**
   * @ngdoc service
   * @name components.service:PollsRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('PollsRest', PollsRest);
}());
